<template lang="pug">
    .error-page
        ._wrap
            img(src="../assets/images/404-brain.png")._brain
            ._caption
                ._title Ошибка 404 — страница не найдена
                ._desc Производится модернизация MERLIN AI, функционал будет доступен в ближайшее время
                router-link(:to="{name: 'home'}")._link {{ text }}
</template>

<script>
export default {
    name: "ErrorPage",
    computed: {
        text: (state) => {
            if (state.$store.getters["auth/authStatus"]) {
                return "Вернуться в свой профиль";
            }
            return "На главную";
        },
    },
};
</script>

<style lang="scss">
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 600px;
    background: url(../assets/images/404-bg.png) #181718 -100px -250px no-repeat;
    background-size: contain;
    @media (max-width: 768px) {
        background-position: center;
        min-height: 480px;
    }

    &__wrap {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1170px;
        width: 100%;
        padding: 0 15px;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__brain {
        @media (max-width: 768px) {
            max-width: 50%;
            width: 100%;
        }
    }

    &__caption {
        padding-left: 80px;
        @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-left: 0;
            padding-top: 40px;
        }
    }

    &__title {
        font-size: 24px;
        color: #cdcdcd;
        @media (max-width: 768px) {
            text-align: center;
        }
    }

    &__desc {
        font-size: 14px;
        margin-top: 40px;
        color: #cdcdcd;
        @media (max-width: 768px) {
            text-align: center;
            margin-top: 25px;
        }
    }

    &__link {
        display: inline-flex;
        margin-top: 40px;
        color: $gold;
    }
}
</style>
